.input-form {
  margin: 2rem auto;
}

.shell-live, .shell-empty {
  position: relative;
  max-width: 24.5rem;
  margin-bottom: 1rem;
  padding: 0 1rem;
  border: 2px solid white;
}

.shell-live h2, .shell-empty h2 {
  position: absolute;
  top: -0.65rem;
  left: 0.5rem;
  color: white;
  font-size: 1rem;
  padding: 0 0.5rem;
  background-color: darkgreen;
}

.shell-buttons {
  display: flex;
  width: auto;
  margin: 1rem auto;
  column-gap: 0.5rem;
}

.btn {
  display: block;
  width: 2rem;
  height: 2rem;
  color: white;
  line-height: 1;
  border-radius: 50%;
}

.reset {
  margin-bottom: 1rem;
}

.reset .btn {
  background-color: rgba(60, 60, 60, 1);
  width: 6rem;
  border-radius: 50vh;
}



.btn.red {
  background-color: rgba(127, 9, 9, 1);
}

.btn.blue {
  background-color: rgba(9, 18, 127, 1);
}

.shells-container {
  position: relative;
  color: white;
}

.shells-container h2 {
  position: absolute;
  top: -0.65rem;
  left: 0.5rem;
  color: white;
  font-size: 1.2rem;
  padding: 0 0.5rem;
  background-color: darkgreen;
}

.child-container {
  display: flex;
  max-width: 30rem;
  padding: 0.8rem;
  column-gap: 0.5rem;
  justify-content: flex-start;
  border: 2px solid white;
  
}

.shell-red {
  width: 3rem;
  height: 6rem;
  background: rgb(36, 0, 0);
  background: linear-gradient(
    270deg,
    rgba(36, 0, 0, 1) 0%,
    rgba(89, 46, 46, 1) 50%,
    rgba(127, 9, 9, 1) 100%
  );
}

.shell-blue {
  width: 3rem;
  height: 6rem;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    270deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(46, 46, 89, 1) 50%,
    rgba(9, 18, 127, 1) 100%
  );
}
