.manual-section {
  position: relative;
  max-width: 30rem;
  color: white;
}

.manual-section h2 {
  font-size: 1.2rem;
  position: absolute;
  top: -0.65rem;
  left: 0.5rem;
  padding: 0 0.5rem;
  background-color: darkgreen;
}

.manual-section p {
  font-size: 0.9rem;
  text-anchor: middle;
  padding: 0.8rem;
  border: 2px solid white;
}

.manual-section p.en {
  margin-top: 6px;
}
