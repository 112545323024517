header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 3rem;
  background-color: darkgrey;
}

h1 {
  font-size: 1.5rem;
  line-height: 1.75;
  margin: 0.25rem 1rem 0;
}

main {
  margin: 4rem 1rem 0;
}

footer {
  margin-top: 5rem;
  height: 3rem;
  background-color: rgb(26, 41, 23);
  color: white;
  text-align: center;
  line-height: 3;
}